import axios from 'axios'
import {Message } from "element-ui";  // 引入
export default {
    //统一的上传接口
    install(Vue) {
        /**
		 * 上传前校验文件是否合法
		 * param1: file文件对象
		 * param2: 当前校验的对象（video， image）
		 */

        Vue.prototype.checkFile = function(file,Type){
            if(Type == 'image'){
                if (!/(png|jpg|jpeg|gif|apng)/gi.test(file.name)) {
                    Message({
                        message: "请上传png|jpg|jpeg|gif|apng格式的图片",
                        type: "error",
                    });
                    return false;
                } else {
                    return true;
                }
            }else if(Type == 'video'){
                if (!/(mp4|m3u8|rmvb|avi|suf|3gp|mkv|flv)/gi.test(file.name)) {
                    Message({
                        message: "您上传的文件类型不正确，请上传视频文件",
                        type: "error",
                    });
                    return false;
                } else {
                    return true;
                }
            }
        }
        
        /*eslint-disable*/
        Vue.prototype.UpHttp = function(file,type){
            return new Promise((resolve, reject) => {
                if(type == 'image'){
                    console.log(file)
                    const form = new FormData()
                    // 文件对象
                    form.append('file', file) //文件流参数
                    form.append('project','om')
                    form.append('modular','minmx')
                    form.append('file_type','image')
                    axios.request({
                        url:'/yc_upload/upload/uploads',
                        method: 'post',
                        baseURL:globalConfig.UpUrl,
                        data:form,
                        timeout: 1000 * 60,
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        },
                        crossDomain:true
                    }).then(res=>{
                        resolve(res)
                    })
                }else{
                    console.log('请选择图片类型')
                }
            })
            
        }
        Vue.prototype.$timeToString = function(val){
          let dates = new Date(val);
          let numTen = num => num>9 ? num : '0'+num;
          let infos = dates.getFullYear() + '-' + 
          numTen(dates.getMonth()+1) + '-' +
          numTen(dates.getDate()) + ' ' + 
          numTen(dates.getHours()) + ':' + 
          numTen(dates.getMinutes()) + ':' + 
          numTen(dates.getSeconds());
          return infos;
        }
    }
}