import Vue from 'vue'
import VueRouter from 'vue-router'

// 登陆
const routes = [
    {
        path: "/login",
        name: "login",
        component: resolve => {
            require(["../views/login/login"], resolve);
        },
        meta: {
            title: "登录"
        }
    },
    {
        path: '/',
        redirect: '/k-gold',
        component: () => import('@/layouts/content'),
        title:'',
        children: [
            // {
            //     path: '/envelope',
            //     component: () => import('@/views/portal/envelope'),
            //     title:'红包雨活动'
               
            // },
            // {
            //     path: '/program',
            //     component: () => import('@/views/portal/program'),
            //     title:'小程序广告'
            // },
            // {
            //     path: '/program/add',
            //     component: () => import('@/views/portal/programAdd'),
            // },
            {
                path: '/score',
                component: () => import('@/views/portal/score'),
                title:'评分页广告'
            },
            {
                path: '/score/scoreAdd',
                component: () => import('@/views/portal/scoreAdd')
            },
            {
                path: '/k-gold',
                component: () => import('@/views/k-gold/k-gold'),
                title:'商家配置',
            },
            {
                path: '/k-gold-trace',
                component: () => import('@/views/k-gold/k-gold-trace'),
                title:'平台配置',
            },
            {
                path: '/staffManage',
                component: () => import('@/views/staffManage/staffManage'),
                title:'员工管理',
            },
            {
                path: '/brandManage',
                component: () => import('@/views/brandManage/brandManage'),
                title:'品牌管理',
            },
            {
                path: '/stores',
                component: () => import('@/views/platform/stores'),
                title:'门店配置',
            },
            {
                path: '/account',
                component: () => import('@/views/platform/account'),
                title:'账户配置',
            },
            {
                path: '/stamps',
                component: () => import('@/views/platform/stamps'),
                title:'送卡发券',
            },
            {
                path: '/stamps/stampsAdd',
                component: () => import('@/views/platform/stampsAdd')
            }
        ]
    }
]
Vue.use(VueRouter)
const router = new VueRouter({
    routes
})

export default router
